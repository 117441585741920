import React from "react"
import NewsCard from "./NewsCard"

import car from "../../images/demo/car.jpg"
import museum from "../../images/demo/museum.jpg"
import bike from "../../images/demo/bike.jpg"
import room from "../../images/demo/room.jpg"
import shoes from "../../images/demo/shoes.jpg"
import cup from "../../images/demo/cup.jpg"
import suitcase from "../../images/demo/suitcase.jpg"




import {
  Grid,
  Typography,
  Container,
} from "@mui/material"

const newsData = [

 
  {
    title: "White Lable Products",
    image: cup,
    link: "/demo/cup",
  },
  {
    title: "Ecommerce WebAR Creater",
    image: suitcase,
    link: "/demo/suitCase",
  },
  {
    title: "3D Configuration Ecommerce Products",
    image: bike,
    link: "/demo/bike",
  },
  {
    title: "WebAR for NATM Las Vegas",
    image: museum,
    link: "/demo/museumAR",
  },
  {
    title: "Explanatory WebAR Products",
    image: car,
    link: "https://neoxr.8thwall.app/car-ar/",
  },
  {
    title: "WebVR Virtual Tour",
    image: room,
    link: "/demo/webvrRoom",
  },
  {
    title: "Snap AR Lens Try-on",
    image: shoes,
    link: "https://lens.snapchat.com/b249e6a1684d40729fc36ddbad46136e?sender_web_id=5649584b-2437-4c4d-a32a-dcb699bfcd8f&device_type=desktop&is_copy_url=true",
  },
]

const NewsSection = () => {
  return (
    <Container sx={{
        padding:"150px 0",
        minHeight:"80vh"
    }}>
      <Typography
        variant="h3"
        component="h3"
        sx={{
          fontFamily: "clash display, sans-serif",
          fontSize: { xs: "1.5rem", md: "2rem" },
          color: "black",
          margin: "30px 5px",
          textAlign: "center",
          lineHeight: "150%",
          maxWidth: "600px",

        }}
      >
        Explore NeoXR Immerse Examples
      </Typography>

      <Grid container spacing={0}>
        {newsData.map((data, index) => (
          <Grid
          key={index}
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              with: "100%",
              justifyContent: "center",
              alignItems: "center",
              padding:"10px 10px"
            }}
          >
            <NewsCard title={data.title}  image={data.image} link={data.link}></NewsCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default NewsSection
